<template>
    <v-row class="text-center" justify="center" 
        align="center">
        <v-col cols="12" class="mt-10">
          <v-icon 
          class="icon black--text"
          size="70"
          dense>fas fa-graduation-cap</v-icon>
      </v-col>
        <v-col cols="12" >
            <h1
                :class="[$vuetify.breakpoint.smAndDown ? 'display-1': 'display-2']" 
                class="font-weight-bold mb-2">
                Nos Formations
            </h1>
        </v-col>

        <v-col cols="12" class="mb-5">
            <h2 
                :class="[$vuetify.breakpoint.smAndDown ? 'display-1': 'display-2']"  >
                Mieux savoir Mieux vivre
            </h2>
            <h2 class="mt-5">
                Nous vous offrons les formations suivantes tout en assurant 
                la meilleure qualité des connaissances aquises 
            </h2>
        </v-col>

        <v-row>
            <v-sheet
                class="mx-auto"
                elevation="10"
                :max-width=" window.width-20 "
            >
                <v-slide-group
                    v-model="model"
                    class="pa-1"
                    center-active
                    >
                    <v-slide-item
                        v-for="(formation, f) in formations"
                        :key="f"
                    >
                     <v-hover v-slot:default="{ hover }">
                        <v-card
                            class="ma-4"
                            height="300"
                            width="200"
                            @click="toggle"
                            flat
                            >
                            <v-expand-transition>
                                <div 
                                    v-if="hover"
                                    class="d-flex transition-fast-in-fast-out v-card--reveal black--text"
                                    :class="formation.transcolor"
                                    style="height: 100%;"
                                    > 
                                    <v-row>
                                        <v-col
                                            cols="12">
                                            <div>
                                                {{formation.short}} 
                                            </div>
                                        </v-col>
                                        <v-col
                                            cols="12">
                                            <router-link  
                                                style="text-decoration:none" 
                                                :to="{ path: url+formation.id, query:{formation}}" >
                                                    <h3 class="black--text">  
                                                        Plus de details
                                                        <v-icon
                                                            color="black">
                                                            fas fa-angle-right
                                                        </v-icon>
                                                    </h3>
                                            </router-link>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-expand-transition>
                            <v-row
                                class="fill-height"
                                align="center"
                                justify="center"
                                >
                                <router-link 
                                    style="text-decoration:none" 
                                    :to="{ path: url+formation.id, query:{formation}}">
                                    <v-col
                                        class="center"
                                        cols="12">
                                        <v-icon
                                            :color="formation.color"
                                            class="mb-3"
                                            size="65"
                                            v-text="formation.icon">
                                        </v-icon>
                                        <div v-if="formation.icon == null">
                                            <v-img :src="formation.icimg" width="100"></v-img>
                                        </div>
                                        <h2 v-text="formation.title"
                                        :class="formation.Fontcolor">  </h2> <br>
                                        <!-- <v-scale-transition>
                                            <v-btn
                                                v-if="active"
                                                class=" animate__animated animate__slideInUp"
                                                text
                                                elevation="0"
                                                small
                                                color="black">
                                                
                                                    
                                                        <v-icon
                                                            class="animate__animated animate__slideInUp mr-2"
                                                            v-if="active"
                                                            color="light-blue darken-4"
                                                            size="20"
                                                            >fas fa-plus</v-icon> 
                                                        Plus d'informations 
                                            </v-btn>
                                        </v-scale-transition> -->
                                    </v-col>
                                </router-link>
                                <!-- <v-divider vertical></v-divider> -->
                            </v-row>
                        </v-card>
                    </v-hover>
                    </v-slide-item>
                </v-slide-group>
            </v-sheet>
        </v-row>

        <v-col
            class="text-center mx-auto my-10"
            cols="12"
            >
            <v-btn
                class="align-self-end"
                elevation="5"
                fab
                x-large
                dark
                @click="$vuetify.goTo('#contact')"
            >
                <v-icon>fas fa-chevron-down</v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: 'Formations',
    data: () => 
    ({
        url:'formation/',
        window: {
            width: 0,
            height: 0
        },
        formations: 
        [
            {
                id:'1',
                title:'Ressources Humaines',
                img:'rh.jpg',
                color:'green darken-2',
                transcolor:'green lighten-5',
                Fontcolor:'green--text text--darken-2',
                short:'Dans un environnement de plus en plus complexe, la formation «Gestion des ressources humaines» (GRH) vise à former des personnels opérationnels en RH.',
                describe:'Dans un environnement de plus en plus complexe, la formation «Gestion des ressources humaines» (GRH) vise à former des personnels opérationnels en RH. L’enjeu est non seulement une occasion d’acquérir les outils les plus performants pour la GRH, il est aussi un moment décisif pour bâtir de nouveaux repères qui donnent sens au travail, à l’implication et aux responsabilités des personnels au sein de l’entreprise.',
                objectifs:
                [
                    {objectif:'Développer une vision globale et stratégique de la fonction RH.'},
                    {objectif:'Posséder les bases juridiques et les pratiques légales et réglementaires de la gestion du personnel.'},
                    {objectif:'Manager les emplois, les compétences, les carrières et la formation.'},
                    {objectif:'Définir et mettre en œuvre le processus de recrutement, d’accueil et d’intégration.'},
                    {objectif:'Intégrer le pilotage économique et l’audit social dans sa pratique.'},
                    {objectif:'S’approprier la législation en matière de risques psychosociaux, santé et sécurité au travail.'},
                    {objectif:'Accompagner les changements stratégiques et organisationnels.'},
                    {objectif:'Maîtriser les outils et savoir-faire techniques nécessaires à la gestion du personnel.'},
                ],
                publicVise:'Responsables du personnel ou des Ressources Humaines en poste (récents ou futurs), cadres ou collaborateurs qui assurent la gestion des RH de l’entreprise ou d’une unité.',
                prog: 
                [
                    {p:'Le droit social'},
                    {p:'Gerer les emplois et les competences'},
                    {p:'Piloter le plan de formation'},
                    {p:'Les relations de travail'},
                    {p:"L'acquisition des compétences"},
                    {p:"Analyse des emplois et des postes de travail"},
                    {p:"Paie et rénumération"},
                    {p:"Le droit du travail"},
                    {p:"Ingenierie de la formation en entreprise"},
                    {p:"Gestion privisionnelle des RH"},
                    {p:"Gestion du recrutement"}, 
                    {p:"L’évaluation des ressources humaines "},
                    {p:"Gestion de la formation des ressources humaines "},
                    {p:"Gérer les emplois et les compétences"},
                    {p:"Les différents  types d’entretien "},
                    {p:"Les avantages et inconvénients des déférentes  méthodes d’entretien "},
                    {p:"Les erreurs d’évaluation lors de l’entretien de recrutement "},
                    {p:"La conduite de l’entretien "},
                    {p:"Le contenu de l’entretien de recrutement "},
                    {p:"Méthodes Pédagogiques "},
                    {p:"La gestion des carrières"},
                    {p:"Améliorer ses capacités en sourcing"},
                    {p:"Les évolutions majeures dans les modes de sourcing "},
                    {p:"Les diﬀérents modes de sourcing "},
                    {p:"Méthodes Pédagogiques"},
                ],
                icon: 'fas fa-user-friends',
                formateurs:
                [
                    { 
                        photo:"",
                        nom:"FERHAOUI",
                        prenom:"Brahim",
                        autre:'',
                        email:"babeloued032019@gmail.com",
                        profile:"",
                        etudes:
                        [
                            {e:"Licence en Science Sociales"},
                            {e:"Post-Graduation Spécialisée en GRH (ENSAG)"},
                            {e:"Participation à plusieurs séminaires et conférences en Management des RH et de la qualité"},
                        ],
                        experiences:
                        [
                            {e:"31 ans d'experience professionnelle dans le domaine RH"},
                        ],
                    },
                    {
                        photo:"",
                        nom:"LOUAHDI",
                        prenom:"Lakhdar",
                        autre:'',
                        email:"louahdilakhdar19@gmail.com",
                        etudes:
                        [
                            {e:"Formation administrative au CFA"},
                            {e:"Formation à l'institut national de formation administrative"},
                            {e:"Diplômé en GRH"},
                        ],
                        experiences:
                        [
                            {e:"30 ans d'experience professionnelle dans le domaine RH"},
                        ],
                    },
                    {
                        photo:"",
                        nom:"KESSOUL",
                        prenom:"Nacera",
                        autre:'',
                        email:"nacerakessoul@yahoo.com",
                        etudes:
                        [
                            {e:"Licence en Sociologie Industrielle"},
                            {e:"Master en Management GRH"},
                            {e:"Participation à plusieurs séminaires et stages en Développement des RH, Leadership, Législation du Travail..."},
                        ],
                        experiences:
                        [
                            {e:"26 ans d'experience professionnelle dans le domaine RH"},
                        ],
                    },
                ]    
            },

            {
                id:'2',
                title:'QHSE',
                img:'qhse1.jpg',
                color:'amber darken-1',
                transcolor:'amber lighten-5',
                Fontcolor:'amber--text text--darken-1',
                short:'Qualité, hygiène, sécurité, environnement (QHSE), est un domaine d’expertise technique contrôlant les aspects liés aux risques professionnels au sein de l’entreprise afin de conduire à un système de management intégré.',
                describe:'Qualité, hygiène, sécurité, environnement (QHSE), est un domaine d’expertise technique contrôlant les aspects liés aux risques professionnels au sein de l’entreprise afin de conduire à un système de management intégré. Le QHSE peut intervenir dans le management de la qualité, le management de la sécurité ou le management environnemental de son entreprise. À ce titre, le responsable QHSE est chargé de veiller à la sécurité du personnel, à sa formation en matière de prévention, au respect des normes, et à la fiabilité des installations dans l’entreprise.',
                objectifs:[
                    {objectif:'Conseiller la Direction dans sa stratégie QHSE.'},
                    {objectif:'Piloter l’amélioration continue du système de management QHSE.'},
                    {objectif:'Manager une équipe de responsables et d’animateurs QHSE.'},
                    {objectif:'Effectuer un état des lieux des pratiques appliquées au sein de la structure'},
                    {objectif:'Evaluer les coûts de la démarche retenue et négocier les ressources nécessaires'},
                    {objectif:'Coordonner les moyens humains et matériels'},
                    {objectif:'Intégrer le référentiel de normes en fonction de la démarche choisie'},
                    {objectif:'Réaliser le planning des tâches en fonction de la politique choisie'},
                    {objectif:'Rédiger, mettre à jour et gérer le système documentaire qualité'},
                    {objectif:'Mener des tests et audits en vue de repérer les points de non-conformité'},
                    {objectif:'Mettre en place un plan d’actions préventives / correctives et l’évaluer'},
                ],
                publicVise:'Directeurs et responsables qualité, responsable RSE, responsables de sécurité et toute personne interessée à developper ses compétences en qualité, RSE et QHSE',
                prog: 
                [
                    {p:"OFFRE DE FORMATION"},
                    {p:"FICHE TECHNIQUE INITIATION HSE "},
                    {p:"FICHE TECHNIQUE CPHS"},
                    {p:"FICHE TECHNIQUE MANAGEMENT HSE"},
                    {p:"FICHE TECHNIQUE GESTION DES DECHETS"},
                    {p:'Auditeur de système qualité'},
                    {p:'Audit qualité'},
                    {p:'La démarche qualité'},
                    {p:'Les normes et le référentiel qualité'},
                    {p:"Le système management qualité"},
                    {p:"Le coût d'obtention de la qualité"},
                    {p:"Les normes de l'hygiène alimentaire"},
                    {p:"Audit interne et certification de marché et label"},
                    {p:"La gestion de l'hygiène de et de la sécurité"},
                    {p:"Diagnostic des besoins en prévention et risques"},
                    {p:"Le plan ORSEC"},
                    {p:"Fondamentaux du HSE"},
                    {p:"Agent en HSE"},
                    {p:"Inspecteur HSE"},
                    {p:"Réglementation et législation HSE"},
                    {p:"La sécurité des chantiers"},
                    {p:"Connaitre les dangers, prévenir les risques"},
                    {p:"La sécurité interne d'un établissement"},
                    {p:"Système de management environnemental"},
                    {p:"Les risques chimiques"},
                    {p:"Les risques incendie"},
                    {p:"Superviseur HSE"},
                    {p:"Géstion des déchets"},
                    {p:"Principes généraux de la prévention"},
                    {p:"Auditeur de système"},
                    {p:"Audi et démarche"},
                    {p:"Les normes  et référentiel "},
                    {p:"Le management système "},
                    {p:"Le coût d'obtention "},
                    {p:"Hygiène alimentaire "},
                    {p:"Audi interne et certification de marche"},
                ],
                formateurs:
                [
                    {
                        photo:"",
                        nom:"OUFAI",
                        prenom:"Hachemi",
                        autre:'',
                        email:"hachemioufai@hotmail.com / oufai.hachemi@gmail.com",
                        etudes:
                        [
                            {e:"Licence en Sociologie"},
                            {e:"Certificat de securité et sauvetage"},
                            {e:"Certificat de formation de conduite défensive"},
                            {e:"Certificat de formateur et evaluateur de conduite défensive"},
                            {e:"Certificat IOSH 'Institution of Occupational Safety and Health'"},
                            {e:"NEBOSH-IGC 'International General Certificate in National Examination Board in Occupational Safety and Health'"},
                            {e:"Certificat de formation des formateurs HSE'"},
                        ],
                        experiences:
                        [
                            {e:"Consultant et formateur HSE (Actuellement)"},
                            {e:"Coordinateur et formateur HSE"},
                            {e:"Safety Manager (4 ans)"},
                            {e:"Conseiller HSE et coordinateur de transport (5 ans)"},
                            {e:"Conseiller en sécurité"},
                            {e:"Contrôleur des coûts"},
                        ],
                    },
                    {
                        photo:"",
                        nom:"NACEUR",
                        prenom:"Cherif",
                        autre:'Mohamed',
                        email:"rozalina6@hotmail.com",
                        profile:"",
                        etudes:
                        [
                            {e:"Licence en Droit"},
                        ],
                        experiences:
                        [
                            {e:"13 ans d'experience professionnelle dans le domaine HSE"},
                        ],
                    },
                ],
                icon: 'fas fa-user-shield'
            },

            {
                id:'3',
                title:'Finance et comptabilité',
                img:'finance.jpg',
                color:'cyan darken-1',
                transcolor:'cyan lighten-5',
                Fontcolor:'cyan--text text--darken-1',
                short:"Cette formation affirme que la comptabilité est un outil au service du projet culturel de l'entreprise, Elle le met en perspective et lui donne une lecture économique.",
                describe:"Cette formation affirme que la comptabilité est un outil au service du projet culturel de l'entreprise. Elle le met en perspective et lui donne une lecture économique. A partir de la compréhension du bilan et du compte de résultat, elle définit les bases des logiques comptables et détaille les modalités de traitement des écritures. Elle articule ensuite la comptabilité générale aux enjeux de la gestion prévisionnelle et financière.",
                objectifs:
                [
                    {objectif:'Acquérir et maîtriser les outils de la gestion financière'},
                    {objectif:"Comprendre les logiques et l'organisation comptables, générales et analytiques"},
                    {objectif:'Communiquer avec les professionnels de la comptabilité'},
                    {objectif:'Concevoir des documents budgétaires adaptés au projet'},
                    {objectif:"poser un premier niveau d'analyse de la situation financière d'une structure"},
                    {objectif:"Pouvoir gérer les finances d'une entreprise, de la comptabilité aux investissements "},
                    {objectif:'Apprendre à réaliser un bilan comptable'},
                    {objectif:'Pouvoir définir des objectifs financiers réalisables et mesurables'},
                    {objectif:'Savoir vérifier des transactions commerciales'},
                ],
                publicVise:'Responsables comptables et finaniers, Controleurs de gestion, auditeur, responsable contentieux et toute personne interessée à developper ses compétences en finance et comptabilité',
                prog: 
                [
                    {p:'La passation des marches'},
                    {p:'La comptabilité pour les décideurs'},
                    {p:"L'analyse financiaire"},
                    {p:"L'audit financier"},
                    {p:"Financement de l'entreprise"},
                    {p:"Finances pour gestionnaires"},
                    {p:"Comptabilité analytique"},
                    {p:"IAS-IFRS"},
                    {p:"Audit comptable et financier"},
                    {p:"La méthode et calcul des coûts"},
                    {p:"La gestion de la trésorie"},
                    {p:"Les amortissements et les prévisions selon NSCFA"},
                    {p:"Le nouveau système comptable et financier algérien NSCFA"},
                    {p:"Elaboration et pilotage du processus budgétaire"},
                    {p:"La géstion budgétaire"},
                ],
                formateurs:
                [
                    {
                        photo:"",
                        nom:"MOUFFAK",
                        prenom:"Ali",
                        autre:'',
                        email:"",
                        etudes:
                        [
                            {e:"Licence en management"},
                            {e:"Master en sciences économiques"},
                            {e:"Doctorat en sciences économiques"},
                        ],
                        experiences:
                        [
                            {e:"Enseignant en :L'économie de l'entreprise, Finance, Management, L'économie générale"},
                            {e:"Membre de la comité scientifique"},
                            {e:"Membre du conseil scientifique"},
                        ],
                    },
                ],
                icon: 'fas fa-file-invoice-dollar'
            },
            // {
            //     id:'4',
            //     title:'Tourisme et Hôtellerie',
            //     img:'finance.jpg',
            //     describe:"La formation Tourisme et Hôtellerie s'adressent à un large public et préparent à des métiers variés de la réception au service, et de la gastronomie à la gestion. Le secteur des métiers de bouche offre de réelles opportunités",
            //     objectif:'',
            //     publicVise:'',
            //     prog: 
            //     [
            //         '1',
            //         '1',
            //         '1',
            //         '1'
            //     ],
            //     icon: 'fas fa-hotel'
            // },

            {
                id:'5',
                title:'Production',
                img:'prod.jpg',
                color:'brown darken-1',
                transcolor:'brown lighten-5',
                Fontcolor:'brown--text text--darken-1',
                short:'Cette formation vous présentera les éléments essentiels de la gestion de production.',
                describe:"Cette formation vous présentera les éléments essentiels de la gestion de production. Elle vous montrera comment la mettre en œuvre, piloter la performance à l'aide d'indicateurs pertinents et améliorer ainsi la productivité.",
                objectifs:
                [
                    {objectif:'Comprendre les enjeux de la gestion de production'},
                    {objectif:"Piloter l’ensemble des flux de la chaîne de production des entreprises"},
                    {objectif:'Utiliser les outils de la gestion de production et en maîtriser les calculs'},
                    {objectif:'Maîtriser le processus de gestion de production'},
                    {objectif:"Suivre et optimiser sa production"},
                    {objectif:"Gagner en productivité"},
                    {objectif:'Réaliser des diagnostics de vos organisations'},
                    {objectif:'Optimiser la conduite et la surveillance de vos installations'},
                ],
                publicVise:'Techniciens production, Ingénieurs production, Responsables production et toute personne souhaitant découvrir les principes de la gestion de production',
                 prog: 
                [
                    {p:'La méthode PERT'},
                    {p:'Méthode et ordonnancement'},
                    {p:"La metrologie"},
                    {p:"La metrologie de base"},
                    {p:"La planification de la production et les systèmes juste à temps"},
                    {p:"Les prévisions et la gestion des ressources"},
                    {p:"La métrologie dimensionnelle"},
                    {p:"Management et organisation de la fonction production"},
                    {p:"Ordonnancement dans la gestion de la production"},
                    {p:"La GPAO"},
                    
                ],
                formateurs:null,
                icon: 'fas fa-industry'
            },

            {
                id:'6',
                title:'Gestion des stocks',
                img:'stock.jpg',
                color:'indigo darken-4',
                transcolor:'indigo lighten-5',
                Fontcolor:'indigo--text text--darken-4',
                short:"Une bonne gestion des stocks implique de bonnes méthodes de prévision et d'approvisionnement ainsi qu'une maîtrise du recensement des besoins.",
                describe:"Une bonne gestion des stocks implique de bonnes méthodes de prévision et d'approvisionnement ainsi qu'une maîtrise du recensement des besoins. Certains outils sont indispensables pour une application immédiate tels que tableaux de bord des approvisionnements, formules, gestion physique des stocks. Il convient de les appliquer rigoureusement pour une gestion efficace.",
                objectifs:
                [
                    {objectif:'Sensibiliser les gestionnaires des stocks sur l’utilité d’une bonne gestion pour éviter la rupture de stocks'},
                    {objectif:"Développer le système d'approvisionnement et de stockage"},
                    {objectif:'Maîtriser la tenue des stocks et les opérations associées'},
                    {objectif:'Maîtriser les opérations essentielles de l’approvisionnement et de la tenue des stocks'},
                    {objectif:"Contribuer à la fiabilisation de la tenue de stocks et à la performance des inventaires"},
                    {objectif:"Élaborer un tableau de bord pertinent sur la tenue des stocks"},
                ],
                publicVise:'Gestionnaires de stocks, Magasiniers, Responsables production et toute personne souhaitant découvrir les principes de la gestion de stock',
                 prog: 
                [
                    {p:'La gestion des stocks'},
                    {p:'Le supply chain management'},
                    {p:"Achat, stock et la chaine logistique"},
                   
                    
                ],
                formateurs:null,
                icon: 'fas fa-cubes'
            },

            {
                id:'7',
                title:'Management',
                img:'management.jpg',
                color:'orange darken-2',
                transcolor:'orange lighten-5',
                Fontcolor:'orange--text text--darken-2',
                short:'La formation management est une formation permettant au collaborateur de monter en compétence sur la partie managériale de son poste tout en confortant son expertise technique.',
                describe:"La formation management est une formation permettant au collaborateur de monter en compétence sur la partie managériale de son poste tout en confortant son expertise technique. Elle permet de valoriser le capital humain de l’entreprise et ainsi de capitaliser durablement sur les enjeux sociaux et économiques. Le manager devient alors un accélérateur de la performance de l’entreprise",
                objectifs:
                [
                    {objectif:'Sensibiliser les gestionnaires des stocks sur l’utilité d’une bonne gestion pour éviter la rupture de stocks'},
                    {objectif:"Développer le système d'approvisionnement et de stockage"},
                    {objectif:'Maîtriser la tenue des stocks et les opérations associées'},
                    {objectif:'Maîtriser les opérations essentielles de l’approvisionnement et de la tenue des stocks'},
                    {objectif:'Identifier ses points de force et ses zones de développement pour gagner en leadership'},
                    {objectif:"Contribuer à la fiabilisation de la tenue de stocks et à la performance des inventaires"},
                    {objectif:"Élaborer un tableau de bord pertinent sur la tenue des stocks"},
                ],
                publicVise:"Chefs de projets, toute personne ayant en chargela conduite d'un projet, manager de proximité, animateur permanent ou occasionnel et toute personne souhaitant découvrir les principes de le management",
                 prog: 
                [
                    {p:'La négociation'},
                    {p:"Initiation au management"},
                    {p:'Le changement organisationnel'},
                    {p:"Le management général"},
                    {p:'Les atitudes au manager'},
                    {p:"L'approche stratégique"},
                    {p:"Le coaching"},
                    {p:"La maitrise des processus"},
                    {p:"La méthode '5S'"},
                    {p:"Audit interne"},
                    {p:"Les techniques du management"},
                    {p:"Résoudre les problèmes et prendre les décisions"},
                    {p:"Le management d'une équipe"},
                    {p:"L'empowerment"},
                    {p:"Le management opérationnel d'une structure"},
                ],
                formateurs:
                [
                    {
                        photo:"",
                        nom:"FERHAOUI",
                        prenom:"Brahim",
                        autre:'',
                        email:"babeloued032019@gmail.com",
                        etudes:
                        [
                            {e:"Licence en Science Sociales"},
                            {e:"Post-Graduation Spécialisée en GRH (ENSAG)"},
                            {e:"Participation à plusieurs séminaires et conférences en Management des RH et de la qualité"},

                        ],
                        experiences:
                        [
                            {e:"31 ans d'experience professionnelle dans le domaine RH"},
                        ],
                    },
                    {
                        photo:"",
                        nom:"ZIAT",
                        prenom:"Hadjer",
                        autre:'',
                        email:"hadjer.ziat@gmail.com",
                        etudes:
                        [
                            {e:"Master Junior en Management de projet"},
                            {e:"Gestion Hôtelerie et Touristique"},

                        ],
                        experiences:
                        [
                            {e:"Housekeeping Manager (Actuellement)"},
                            {e:"Responsable commerciale (3 ans)"},
                            {e:"Directrice technique (2 ans)"},
                            {e:"Attachée commerciale"},
                        ],
                    },
                    {
                        photo:"",
                        nom:"AISSAT",
                        prenom:"Mebarek",
                        autre:'',
                        email:"",
                        etudes:
                        [
                            {e:"Diplôme à l'ENA"},
                            {e:"PGS en gestion des entreprises"},
                            {e:"PGS en management de stratégie bancaire"},
                            {e:"PLD en management publique"},

                        ],
                        experiences:
                        [
                            {e:"Chargé de missions"},
                            {e:"Directeur central des RH"},
                            {e:"Chef d'etude"},
                            {e:"Chef de service"},
                            {e:"Secrétaire général"},
                        ],
                    },
                ],
                icon: 'fas fa-tasks'
            },
            {
                id:'8',
                title:'Communication',
                img:'comm.jpg',
                color:'light-blue darken-2',
                transcolor:'light-blue lighten-5',
                Fontcolor:'light-blue--text text--darken-2',
                short:'Un professionnel de la communication est un véritable chef d’orchestre qui conçoit et met en œuvre les actions auprès des différents publics ciblés par l’entreprise.',
                describe:"Un professionnel de la communication est un véritable chef d’orchestre qui conçoit et met en œuvre les actions auprès des différents publics ciblés par l’entreprise. Ses atouts : des stratégies innovantes et une créativité intarissable. La pratique de la communication est indissociable de la vie en société. On parle même aujourd’hui de société de communication.",
                objectifs:
                [
                    {objectif:'Avoir une communication interne dynamique et efficace'},
                    {objectif:"Apprendre à communiquer à un public ciblé"},
                    {objectif:"Connaître les techniques d'une communication interne efficace"},
                    {objectif:'Maîtriser les nouveaux médias et leur mode de fonctionnement'},
                    {objectif:"gérer la réputation d'une personne ou d'une entreprise"},
                ],
                publicVise:"Responsable de la communication externe/interne, Responsable des relations publiques / des relations presse. Chargé(e) de communication , manager de proximité, animateur permanent ou occasionnel et toute personne souhaitant développer ses capacités en communication",
                 prog: 
                [
                    {p:'Le plan de ccommunication'},
                    {p:"La communication en entreprise"},
                    {p:'La communication externe'},
                    {p:"Evaluer la communication"},
                    {p:'Le gouvernement et le TIC'},
                    {p:"La problematique de l'information et de la communication"},
                    {p:'L’aide à la prise de décision'},
                    {p:"La pensé stratégique"},
                    {p:'La gestion du temps'},
                    {p:"L’efficacité de la communication personnelle et professionnelle"},
                    {p:'Le coaching'},
                    {p:"Gestion du stress"},
                    {p:"Développement personnel"},
                ],
                formateurs:
                [
                    {
                        photo:"",
                        nom:"MARICHAL",
                        prenom:"Pierre",
                        autre:'',
                        email:"",
                        etudes:null,
                        experiences:
                        [
                            {e:"Formateur expert en communication"},
                            {e:"Maitre praticien en PNL et communication globale"},
                        ],
                    },
                ],
                icon: 'fas fa-comments'
            },
            {
                id:'9',
                title:'Commerce et Marketing',
                img:'market.jpg',
                color:'deep-purple darken-4',
                transcolor:'deep-purple lighten-5',
                Fontcolor:'deep-purple--text text--darken-4',
                short:'Le commerce, le développement, le marketing et les services sont autant de fonctions nécessaires à la promotion d’une entreprise, de ses produits et services. ',
                describe:"Le commerce, le développement, le marketing et les services sont autant de fonctions nécessaires à la promotion d’une entreprise, de ses produits et services. Ces activités, stratégiques, évoluent rapidement et s’adaptent à leurs environnements et aux transformations que subissent les marchés.",
                objectifs:
                [
                    {objectif:'Maîtriser un savoir, des savoir-faire et savoir-être, nécessaires à une intégration et à une évolution en entreprise.'},
                    {objectif:"Piloter et mettre en oeuvre des décisions et choix stratégiques sur les produits et services proposés par l’entreprise"},
                    {objectif:"Développer des capacité à prendre des initiatives, à agir en leader et à travailler en équipe, qualités indispensables à la réussite professionnelle des futurs managers"},
                    {objectif:'Manager une équipe pour mener à bien des projets opérationnels'},
                ],
                publicVise:"Directeurs commerciaux, Directeurs de marketing. chefs de vente, Responsable relation client, Vendeurs et toute personne souhaitant développer ses capacités en commerce et marketing",
                 prog: 
                [
                    {p:'Maitriser les techniques commerciales de la vente'},
                    {p:"Perfectionner sa demarche commerciale"},
                    {p:'Mieux negocier pour mieux vendre'},
                    {p:"Mettre en place un plan d’actions commerciales"},
                    {p:'Cibler et organiser la prospection'},
                    {p:"L’accueil, premiere image de l’entreprise"},
                    {p:"Developper le marketing clientele"},
                    {p:"Initiation a la politique commerciale"},
                    {p:"Fidelisation de la clientele"},
                    {p:"La communication commerciale"},
                    {p:"Comment suivre et assurer une force de vente"},
                    {p:"Formation pratique a la vente"},
                    {p:"Les outils marketing"},
                    {p:"Maitriser les techniques de vente"},
                    {p:"Chef de produit"},
                    {p:"L’assistant marketing"},
                    {p:"La prospection"},
                    {p:"Conquerir de nouveaux clients"},
                    {p:"La vente"},
                    {p:"Le produit"},
                    {p:"La distribution"},
                    {p:"Management de la force de vente"},
                    {p:"Le marketing"},
                    {p:"La force de vente"},
                    {p:"Marketing touristique"},
                    {p:"Le comportement du consommateur"},
                    {p:"Le mix-marketing"},
                    {p:"Technique de vente"},
                    {p:"Communication avec le client"},
                    {p:"Télé-conseiller"},
                ],
                formateurs:
                [
                    {
                        photo:"",
                        nom:"NAFAI",
                        prenom:"Asma",
                        autre:'',
                        email:"asma.nafai@outlook.fr",
                        etudes:
                        [
                            {e:"Master en Environement marin"},
                        ],
                        experiences:
                        [
                            {e:"Responsable Marketing digitale (Actuelement)"},
                            {e:"Responsable clientèle et gestion financière (2 ans)"},
                            {e:"Attachée commercial (1 an)"},
                        ],
                    },
                ],
                icon: 'fas fa-comment-dollar'
            },

            {
                id:'10',
                title:'Informatique et Bureautique',
                img:'info.jpg',
                color:'green darken-4',
                transcolor:'green lighten-5',
                Fontcolor:'green--text text--darken-4',
                short:'Toutes les Entreprises, sans exception, utilisent les logiciels bureautiques (Word, Excel, etc…) et savoir les maîtriser est devenu UNE NÉCESSITÉ ABSOLUE pour tout le monde.',
                describe:"Toutes les Entreprises, sans exception, utilisent les logiciels bureautiques (Word, Excel, etc…) et savoir les maîtriser est devenu UNE NÉCESSITÉ ABSOLUE pour tout le monde. Il est difficile aujourd’hui de trouver un travail lorsque l’on ne maîtrise pas les logiciels bureautiques comme Word, Excel ou même parfois Access. ",
                objectifs:
                [
                    {objectif:'Apprendre à utiliser le micro-ordinateur PC, son environnement et savoir utiliser les fonctions essentielles de Windows'},
                    {objectif:"Commencer à travailler avec Word, Excel, PowerPoint et Outlook"},
                    {objectif:"Apprendre également à travailler avec Internet, gérer vos emails et sécuriser votre ordinateur."},
                    {objectif:'Maîtriser les logiciels en découvant les fonctions avancées de la suite Office (Word, Excel, PowerPoint)'},
                ],
                publicVise:"cadres de toutes directions, Personnel cadre informatique, Informaticiens, Programmeurs, Analystes,  agents de saisie, secrétaires et toute personne souhaitant développer ses capacités en Informatique et bureautique",
                 prog: 
                [
                    {p:'Excel'},
                    {p:"Word approfondi"},
                    {p:'Bases de donnees reseaux'},
                    {p:"Gestion du temps par l’outil informatique"},
                    {p:'Dévelopement WEB'},
                    {p:'Reseaux informatiques'},
                    {p:"Systeme d’exploitation"},
                    {p:"Securite informatique"},
                    {p:"Autocad 2006-2008"},
                    {p:"Reseau wifi"},
                    {p:"Messagerie electronique"},
                    {p:"Power- point"},
                    {p:"Access"},
                    {p:"Excel approfondi"},
                    {p:"Excel 2010"},
                    {p:"Management avec excel"},
                ],
                formateurs:
                [
                    {
                        photo:"",
                        nom:"BENNAMANE",
                        prenom:"Ali",
                        autre:'',
                        email:"bennamaneali@hotmail.com",
                        etudes:
                        [
                            {e:"Ingenieur en Informatique"},
                            {e:"Certification Réseau (MCSE)"},
                            {e:"Master en management de projets"},
                            {e:"Expert international"},
                        ],
                        experiences:
                        [
                            {e:"Gérant de son propre bureau d'étude (Actuellement)"},
                            {e:"Membre du réseau Euro-méditerranéen de recherche en technologies de l'information et de la communication (depuis 2010)"},
                            {e:"22 ans d'experience professionnelle (Expert consultant)"},
                        ],
                    },
                ],
                icon: 'fas fa-laptop-code'
            },
            {
                id:'12',
                title:'Contentieux',
                img:'contentieux.jpg',
                color:'brown darken-3',
                transcolor:'brown lighten-5',
                Fontcolor:'brown--text text--darken-3',
                short:'La gestion du contentieux est devenue un domaine incontournable au sein des entreprises.',
                describe:"La gestion du contentieux est devenue un domaine incontournable au sein des entreprises. Economiser du temps et de l’argent font partie des priorités ! C’est pourquoi vous devez savoir mettre en place les procédures de façon optimale.",
                objectifs:
                [
                    {objectif:"Connaitre ce qu’est le contentieux"},
                    {objectif:'Acquérir les règles pour le gérer'},
                    {objectif:"outiller les participants à la prise en charge des différends entre les entreprises conformément aux nouvelles dispositions du code de procédure civile "},
                    {objectif:"Savoir suivre et contrôler la gestion des dossiers"},
                    {objectif:"Bien connaitre les règles de procédure Instaurées entre l’entreprise et ses avocats"},
                ],
                publicVise:"Directeurs et cadres de l’administration, Staffs de directions générales, Chargés du contentieux, Juristes, Gestionnaires, Toute personne concernée ou intéressée par le thème",
                 prog: 
                [
                    {p:'Le contentieux'},
                    {p:"Le reglement des litiges"},
                    {p:'La pratique du contentieux judiciaire le contentieux'},
                ],
                formateurs:null,
                icon: 'fas fa-gavel'
            },
            {
                id:'13',
                title:'Gestion de Projets',
                img:'gest.jpg',
                color:'purple darken-3',
                transcolor:'purple lighten-5',
                Fontcolor:'purple--text text--darken-3',
                short:'Pour mener à bien les projets qui leur sont confiés, les chefs de projet gagnent à connaître le maximum de méthodes pour : cadrer, définir les objectifs et le périmètre.',
                describe:"Pour mener à bien les projets qui leur sont confiés, les chefs de projet gagnent à connaître le maximum de méthodes pour : cadrer, définir les objectifs et le périmètre, maîtriser les risques, organiser les ressources et planifier les tâches du projet. ORSYS apporte les connaissances et savoir-faire clés pour piloter un projet de son début jusqu’à sa clôture",
                objectifs:
                [
                    {objectif:"Concevoir un projet"},
                    {objectif:'Faire l’analyse des données'},
                    {objectif:"Intégrer la gestion de projet dans le système d’information et dans le management de l’entreprise."},
                    {objectif:"Former les outils pour l’élaboration de solutions de planification personnalisées"},
                    {objectif:"Maitriser la gestion des ressources dans l’affectation des coûts"},
                    {objectif:"Améliorer l’aspect communication des projets"},
                    {objectif:"Acquérir l’ensemble des outils et techniques de gestion"},
                ],
                publicVise:"Directeurs de projet, Chefs de projets, Planificateurs, Ingénieurs projets, Responsables de structure (ingénierie de travaux), Conducteurs de travaux, Agents d’ordonnancement, Gestionnaires et Toute personne concernée ou intéressée par le thème",
                 prog: 
                [
                    {p:'Planification et suivi des projets avec primavera'},
                    {p:"Planification et suivi des projets avec primavera projet planner"},
                    {p:'Conduite des travaux'},
                    {p:'Ms project'},
                    {p:'Management de projet avec le logiciel version 2003-2007'},
                    {p:'Calcul des couts et prix de revient dans le BTPH'},
                    {p:'Les chantiers : formation pour agents d’execution'},
                    {p:'Les chantiers : formation pour cadres des chantiers'},
                    {p:'Ms project 2010'},
                    {p:' Management de projets'},
                    {p:'Gestion des chantiers'},
                    {p:'Techniques et outils de planification '},
                ],
                formateurs:null,
                icon: 'fas fa-project-diagram'
            },
            {
                id:'11',
                title:"Création d'entreprise",
                img:'entreprise.jpg',
                color:'teal darken-1',
                transcolor:'teal lighten-5',
                Fontcolor:'teal--text text--darken-1',
                short:"L’objectif de cette formation est de vous initier à tous les aspects de la création d’entreprise",
                describe:"L’objectif de cette formation est de vous initier à tous les aspects de la création d’entreprise et savoir franchir les étapes qui vous permettront de devenir votre propre chef d’entreprise.",
                objectifs:
                [
                    {objectif:"Acquérir des méthodes pour monter votre business plan et votre stratégie d'entreprise"},
                    {objectif:'Etude du référentiel de compétences du micro entrepreneur, de l’entrepreneur, du responsable de l’entreprise'},
                    {objectif:"Comprendre la méthodologie de la création d'une entreprise et la gérer"},
                    {objectif:"Identifier les centres de ressources qui vous seront utiles après le démarrage de votre entreprise"},
                ],
                publicVise:"Micro-entrepreneurs,  Entrepreneurs, Responsables d’entreprises, Toute personne porteuse d’un projet de création d’entreprise",
                 prog: 
                [
                    {p:'Le projet d’entreprise'},
                    {p:"Le marché et l’implantation de l’entreprise"},
                    {p:'Environnement économique de l’entreprise en Algérie (étude de marché)'},
                    {p:"Le plan d’affaires ou business plan"},
                    {p:'Les phases de création d une entreprise'},
                    {p:"Formalités de constitution de l’entreprise"},
                    {p:"Conception du projet et dossier de création de l’entreprise (ANSEJ, CNAC)"},
                    {p:"Données sur la situation de= l’entreprise"},
                    {p:"Données comptables et financières"},
                    {p:"Gestion stratégique de l’entreprise et organisation"},
                    {p:"Structure interne de l’entreprise"},
                    {p:"Les fonctions de l’entreprise"},
                ],
                formateurs:null,
                icon: 'fas fa-briefcase'
            },
            {
                id:'14',
                title:'Banque et Assurances',
                img:'banque.jpg',
                color:'blue-grey darken-3',
                transcolor:'blue-grey lighten-5',
                Fontcolor:'blue-grey--text text--darken-3',
                short:'Le secteur de la banque et de l’assurance est un poids lourd dans l’économie française : c’est un secteur qui recrute en permanence et qui a besoin d’employés de banque et d’employés des assurances.',
                describe:"Le secteur de la banque et de l’assurance est un poids lourd dans l’économie française : c’est un secteur qui recrute en permanence et qui a besoin d’employés de banque et d’employés des assurances, des métiers qui évoluent avec la transformation numérique des entreprises. Les techniciens, les attachés commerciaux, les cadres sont également dans la ligne de mire des recruteurs.",
                objectifs:
                [
                    {objectif:"Apprendre les règles qui régissent les opérations des banques"},
                    {objectif:'Connaître les différents types de montages financiers'},
                    {objectif:"Connaître et appliquer le contrôle de gestion bancaire"},
                    {objectif:"Apprendre comment se fait le financement d’un projet"},
                ],
                publicVise:"Directeurs financiers, cadres comptables et financiers, Financiers, Chargés de clientèle, Juristes, cadres des banques et Toute personne concernée ou intéressée par le thème",
                 prog: 
                [
                    {p:'Droit bancaire'},
                    {p:"Montages financiers"},
                    {p:'Controle de gestion bancaire'},
                    {p:'Financement de projets'},
                    {p:".Opérations de caisse"},
                    {p:"Opérations de portefeuille"},
                    {p:"Commerce international"},
                    {p:"Les opérations du commerce extérieur"},
                    {p:"Les techniques de financement du  commerce extérieur"},
                    {p:"Les crédits"},
                    {p:"Les crédits d’exploitation"},
                    {p:"Les crédits d’investissement"},
                    {p:"Les risques de crédits"},
                    {p:"Analyse des risques"},
                    {p:"Gestion des risques"},
                    {p:"Analyse financière"},
                    {p:"Evaluation des projets d’investissements"},
                    {p:"Comptabilité générale"},
                    {p:"Comptabilité bancaire"},
                    {p:"audit interne de l’entreprise EPIC et EPE"},
                    {p:"gestion des marchés publics "},
                    {p:"gestion des ressources humaines (recrutement et   déroulement des carrières)"},
                    {p:"Gestion des risques financiers"},
                    {p:"Gestion bancaire"},
                    {p:"Évaluation d’entreprise"},
                    {p:"Instruments dérivés"},
                    {p:"Techniques des marchés financiers"},
                    {p:"Gestion obligatoire"},
                    {p:"Analyse transactionnelle"},
                    {p:"Finance islamique"},
                    {p:"Management stratégique"},
                    {p:"Choix des investissements"},
                    {p:"Analyse des titres cotés en bourse"},
                    {p:"Gestion Actif Passif"},
                    {p:"Marché des changes"},
                    {p:"Customer Value Proposition"},
                    {p:"Mécanismes Economiques Internationaux"},
                    {p:"Finance non-financière"},
                ],
                formateurs:
                [
                    {
                        photo:"",
                        nom:"ALIANE",
                        prenom:"Kamel",
                        autre:'',
                        email:"aliane@caat.dz / kaliane_caat@yahoo.fr",
                        etudes:
                        [
                            {e:"Licence en droit"},
                            {e:"Magister Contrat et Résponsabilité"},
                            {e:"Master en Assurances et management"},

                        ],
                        experiences:
                        [
                            {e:"Directeur Central de l'Audit CAAT Assurance (Actuellement)"},
                            {e:"Cadre superieur détaché CAAT Assurance (1 an)"},
                            {e:"Directeur Succursale CAAT Assurance (3 ans)"},
                            {e:"Chef de departement Recours et Avaries communes  (11 ans)"},
                            {e:"Chef de service Recours (8 ans)"},
                            {e:"Chargé d'étude NI CAAT Assurance (1 an)"},
                        ],
                    },
                    {
                        photo:"",
                        nom:"MOKRETAR",
                        prenom:"Raif",
                        autre:' KARROUBI',
                        email:"mkraif@yahoo.com",
                        etudes:
                        [
                            {e:"Licence en Economie"},
                            {e:"3eme Cycle en Finance et banque"},
                            {e:"Humphrey Fellowship en Finance"},
                            {e:"Diplome de Recherche Stratégies de Developpement"},

                        ],
                        experiences:
                        [
                            {e:"Banking Specialist (Actuellement)"},
                            {e:"Expert (Actuellement)"},
                            {e:"Directeur Général Adjoint (3 ans)"},
                            {e:"Project Officer (3 ans)"},
                            {e:"Consultant à court terme"},
                            {e:"Vice Président (3 ans)"},
                            {e:"Dirécteur Général (2 ans)"},
                            {e:"Professeur en : Instruments dérivés, Ingénieurie Financière, Fusion acquisitions, Marchés financiers, Trading, Management Bancaire... (3 ans)"},
                            {e:"Chef d'étude (1 an)"},
                            {e:"Trader"},
                        ],
                    },
                ],
                icon: 'fas fa-landmark'
            },
            {
                id:'15',
                title:'Esthetique',
                img:null,
                icimg:require("@/assets/esthetique.png"),
                color:'green',
                transcolor:'green lighten-5',
                Fontcolor:'green--text text--darken-5',
                short:null,
                describe:null,
                objectifs:null,
                publicVise:null,
                 prog: 
                [
                    {p:'Coloration'},
                    {p:"Mèches/balayage "},
                    {p:'coupe '},
                    {p:'Maquillage de jour / nuit '},
                    {p:'Kératine capillaire (homme /femme)'},
                    {p:'Protéine capillaire (homme /femme)'},
                    {p:'Botox capillaire (homme /femme)'},
                    {p:'Soin cheveux'},
                    {p:'Coiffure simple et soirée'},
                    {p:'Soin du Corp. '},
                    {p:'Soin du visage '},
                    {p:'Modelage faciale'},
                    {p:'Modelage main '},
                    {p:'Massage relaxant (homme /femme)'},
                    {p:'Massage anti stress (homme /femme)'},
                    {p:'Massage femme enceinte '},
                    {p:'Réflexologie plantaire (homme /femme)'},
                    {p:'Extension et rehaussement de cil'},
                    {p:'Microblading'},
                    {p:'microshading '},
                    {p:'Soin manucure (homme /femme)'},
                    {p:'Soin pédicure (homme /femme)'},
                    {p:'Maquillage permanent '},
                    {p:'Epilation'},
                    {p:'Hijama esthétique'},
                    {p:'Keropratique'},
                    {p:'Hygiène'},
                    {p:'Anatomie de l’ongle '},
                    {p:'Maladie de l’ongle '},
                    {p:'Les types et formes d’ongle naturel  '},
                    {p:'Lexique professionnel (produits et matériels) '},
                    {p:'Quelle technique pour quel type d’ongle'},
                    {p:'Renforcement'},
                    {p:'Pose Vernis semi-permanent'},
                    {p:'Pose gel sur ongles naturels'},
                    {p:'Pose gel sur capsules '},
                    {p:'Extension sur chablon avec gel '},
                    {p:'La préparation de l’ongle'},
                    {p:'Les formes de l’ongle (5formes)'},
                    {p:'Carre '},
                    {p:'Ballerine / Coffin'},
                    {p:'Arrondi  '},
                    {p:'Amande '},
                    {p:'Pointu '},
                    {p:'Nail art: (French reverse, aquarium, baby-boomer)'},
                    {p:'Remplissage '},
                    {p:'Depose '},
                ],
                // formateurs:
                // [
                //     {
                //         photo:"",
                //         nom:"ALIANE",
                //         prenom:"Kamel",
                //         autre:'',
                //         email:"aliane@caat.dz / kaliane_caat@yahoo.fr",
                //         etudes:
                //         [
                //             {e:"Licence en droit"},
                //             {e:"Magister Contrat et Résponsabilité"},
                //             {e:"Master en Assurances et management"},

                //         ],
                //         experiences:
                //         [
                //             {e:"Directeur Central de l'Audit CAAT Assurance (Actuellement)"},
                //             {e:"Cadre superieur détaché CAAT Assurance (1 an)"},
                //             {e:"Directeur Succursale CAAT Assurance (3 ans)"},
                //             {e:"Chef de departement Recours et Avaries communes  (11 ans)"},
                //             {e:"Chef de service Recours (8 ans)"},
                //             {e:"Chargé d'étude NI CAAT Assurance (1 an)"},
                //         ],
                //     },
                //     {
                //         photo:"",
                //         nom:"MOKRETAR",
                //         prenom:"Raif",
                //         autre:' KARROUBI',
                //         email:"mkraif@yahoo.com",
                //         etudes:
                //         [
                //             {e:"Licence en Economie"},
                //             {e:"3eme Cycle en Finance et banque"},
                //             {e:"Humphrey Fellowship en Finance"},
                //             {e:"Diplome de Recherche Stratégies de Developpement"},

                //         ],
                //         experiences:
                //         [
                //             {e:"Banking Specialist (Actuellement)"},
                //             {e:"Expert (Actuellement)"},
                //             {e:"Directeur Général Adjoint (3 ans)"},
                //             {e:"Project Officer (3 ans)"},
                //             {e:"Consultant à court terme"},
                //             {e:"Vice Président (3 ans)"},
                //             {e:"Dirécteur Général (2 ans)"},
                //             {e:"Professeur en : Instruments dérivés, Ingénieurie Financière, Fusion acquisitions, Marchés financiers, Trading, Management Bancaire... (3 ans)"},
                //             {e:"Chef d'étude (1 an)"},
                //             {e:"Trader"},
                //         ],
                //     },
                // ],
                icon: null
            },
            // {
            //     id:'15',
            //     title:'Développement Personnel',
            //     describe:'',
            //     objectif:'',
            //     publicVise:'',
            //     prog: 
            //     [
            //         '1',
            //         '1',
            //         '1',
            //         '1'
            //     ],
            //     icon: 'fas fa-user-check'
            // },
        ]
    }),
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        }
    }
}
</script>